import PropTypes from 'prop-types'
import React, {useEffect, useState} from "react"
import { Col, Card, CardBody } from "reactstrap"
import CountUp from 'react-countup';
import ReactApexChart from "react-apexcharts"
import api from '../../utils/Api'

const MiniWidget = ({date}) => {
  const [data, setData] = useState([])

  useEffect(()=>{
    api.request('dashboard/stats',(d)=>{
      setData(d)
    }, {date})
  }, [date])

  return (
    <React.Fragment>
      {data.map((report, key) => (
        <Col md={6} xl={3} key={key}>
          <Card>
            <CardBody>
              {/*<div className="float-end mt-2">
                <ReactApexChart
                  options={report.options}
                  series={report.series}
                  type= {report.charttype}
                  height={report.chartheight}
                  width={report.chartwidth}
                />
              </div>*/}
              <div>
                <h4 className="mb-1 mt-1"><span>{typeof report.value == 'number'?<CountUp end={report.value} separator=","  />:report.value}</span></h4>
                <p className="text-muted mb-0">{report.label}</p>

              </div>
              {/*<p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
              </p>*/}
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  )
}

export default MiniWidget

MiniWidget.propTypes = {
  reports: PropTypes.array
}
