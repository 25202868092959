import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter , Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Modal, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import api from '../../../utils/Api'
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import SweetAlert from "react-bootstrap-sweetalert"
import csvDownload from 'json-to-csv-export'
import moment from 'moment'
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import _ from 'lodash'
import { getUsers } from "../../../store/contacts/actions"
import contactListColumns from "./contactListColumns"
import { isEmpty } from "lodash"

let deletedId = null

function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const states = [{
  label: 'All',
  value: ''
},{
  label: 'Active',
  value: 'active'
},{
  label: 'Completed',
  value: 'completed'
},{
  label: 'Cancelled',
  value: 'cancelled'
},]

const types = [{
  label: 'All',
  value: ''
},{
  label: 'Ask',
  value: 'seller'
},{
  label: 'Bid',
  value: 'buyer'
}]

const categories = [{
  label: 'All',
  value: ''
}, {
    "value": "rice",
    "label": "Rice"
},
{
    "value": "cassava",
    "label": "Cassava"
},
{
    "value": "rubber",
    "label": "Rubber"
},
{
    "value": "sugar",
    "label": "Sugar"
},
{
    "value": "other",
    "label": "Other"
},]

const ContactsList = props => {

  const { users, onGetUsers, match } = props
  const [userList, setUserList] = useState([])
  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 10,
    totalSize: 0, // replace later with size(users),
    custom: true,
  })
  const [params, setParams] = useState({type: ''})
  const [page, setPage] = useState(1)
  const [showEdit, setShowEdit] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [exporting, setExporting] = useState(false)
  const [profileImage, setProfileImage] = useState(null)
  const [s, setS] = useState('')

  const searchRef = useRef()

  const fetch = (page = 1) => {
    setPage(page)
    api.request('transaction', ({data, total, perPage})=>{
        setUserList(data)
        setPageOptions({...pageOptions, totalSize: total, sizePerPage: perPage})
    }, {page, user_id: match.params.id ? match.params.id : '', s, ...params})
  }

  const { SearchBar } = Search

  // useEffect(() => {
  //   onGetUsers()
  //   setUserList(users)
  // }, [users, onGetUsers])
  //
  // useEffect(() => {
  //   if (!isEmpty(users)) {
  //     setUserList(users)
  //   }
  // }, [users])


  useEffect(()=>{
    if (!s && searchRef.current) {
      searchRef.current.value = ''
    }
    fetch()
  }, [s, params])

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {

  }

  var selectRowProp = {
    mode: "checkbox",
    clickToSelect: true,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Transactions" breadcrumbItem="Transaction List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={userList || []}
                        columns={contactListColumns((user)=>{
                          //setEditUser(user)
                          setShowEdit(true)
                        }, (id)=>{
                          setConfirm(true)
                          deletedId = id
                        }, (field, sort)=>{
                          setParams({...params, order: sort, order_by: field})
                        })}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="row mb-2">
                              {/*<Col md={6}>
                                <div className="mb-3">
                                  <Link onClick={()=>setShowEdit(true)} className="btn btn-success waves-effect waves-light"><i className="mdi mdi-plus me-2"></i> Add New Seller</Link>
                                </div>
                              </Col>*/}

                              {/*<Col md={6}>
                                <div className="form-inline float-md-end mb-3">
                                  <div className="search-box ms-2">
                                    <div className="position-relative">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      <i className="mdi mdi-magnify search-icon"></i>
                                    </div>
                                  </div>
                                </div>
                              </Col>*/}
                              <Col xs={12} md={3}>
                                <Button color="primary" disabled={exporting} onClick={(e)=>{
                                  e.preventDefault()
                                  setExporting(true)
                                  api.request('transaction', (r, s)=>{
                                    if (!s) {
                                      setExporting(false)
                                      return
                                    }
                                    csvDownload(r.map((i)=>({
                                      "User Name": i.user.first_name + (i.user.last_name?(' '+i.user.last_name): ''),
                                      "Account ID": i.user.account_id,
                                      "Posting ID": i.posting_id,
                                      "Transaction ID": i.transaction_id,
                                      Type: i.user.role=='seller'?'Ask':'Bid',
                                      "Counterparty ID": i.counterparty.account_id,
                                      "Initial Bid Quantity": i.initial_bid_quantity,
                                      "Posting Price/kg": i.price,
                                      "Initial Bid Price/kg": i.initial_bid_price,
                                      "Closing Date": i.closing_date,
                                      "Closing Quantity": i.closing_quantity,
                                      "Closing Price": i.closing_price,
                                      "Reason for Close": i.reason,
                                      "As of Date": i.as_of_date,
                                    })), "transactions_"+moment().format('YYYY-MM-DD')+".csv")
                                    setExporting(false)
                                  }, {get_all: 1, user_id: match.params.id ? match.params.id : '', s, ...params})

                                }}>
                                  Export as CSV
                                </Button>
                              </Col>
                              <Col xs={12} md={9} className="row-vcenter justify-content-end flex-wrap">
                                <UncontrolledDropdown className="form-inline mb-3 mx-2">
                                    <DropdownToggle tag="a" className="text-reset cursor-pointer" id="dropdownMenuButton5" caret>
                                        <span className="fw-semibold">Type: </span> <span className="text-muted">{_.find(types, ['value', params.type]).label}<i className="mdi mdi-chevron-down ms-1"></i></span>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        { types.map(({label, value})=>(
                                          <DropdownItem key={value} onClick={()=>setParams({...params, type: value})}>{label}</DropdownItem>
                                        ))
                                        }
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <form onSubmit={(e)=>{
                                  e.preventDefault()
                                  const formData = new FormData(e.target)
                                  setS(formData.get('s'))
                                }}>
                                  <div className="form-inline mb-3">
                                    <div className="search-box ms-2" style={{width: 300}}>
                                      <div className="row-vcenter position-relative form-control">
                                        <input  id="search-bar-0" type="text" name="s" aria-labelledby="search-bar-0-label"
                                        class="border-0 form-control p-0" style={{borderRadius: 0}} ref={searchRef}
                                         placeholder="Search by User Name, Posting ID" />
                                        { !!s &&
                                          <i onClick={()=>setS('')} className="mdi mdi-close cursor-pointer"></i>
                                        }
                                        <i className="mdi mdi-magnify search-icon"></i>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive mb-4">
                                  <BootstrapTable
                                  //selectRow={selectRowProp}
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap mb-0"
                                    }
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="float-sm-end">
                              <PaginationListStandalone
                                {...paginationProps}
                                onPageChange={(page)=>fetch(page)}
                                page={page}
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {confirm ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setConfirm(false)
            api.request('user/' + deletedId, ()=>{
              fetch()
            }, {}, {method: 'DELETE'})
          }}
          onCancel={() => setConfirm(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

ContactsList.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
}

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
})

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactsList))
