import React, {useState, useEffect} from "react"
import { Card, CardBody, DropdownToggle, DropdownMenu, DropdownItem, Table, UncontrolledDropdown, Badge } from "reactstrap"
import { Link } from "react-router-dom"
import FeatherIcon from "feather-icons-react"
//Simple bar
import SimpleBar from "simplebar-react"

import api from '../../utils/Api'
import {numberFormat} from '../../utils/Functions'
import _ from 'lodash'

const periods = [{
  label: 'Yearly',
  value: 'year'
},{
  label: 'Monthly',
  value: 'month'
},{
  label: 'Weekly',
  value: 'week'
},]

const TopUser = ({date}) => {
    const [data, setData] = useState([])
    const [period, setPeriod] = useState(periods[0].value)

    useEffect(()=>{
       api.request('dashboard/top-users',(d)=>{
         setData(d)
       }, {period, date})
    }, [period, date])
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    {/*<div className="float-end">
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="text-reset" id="dropdownMenuButton5" caret>
                                <span className="text-muted">All Members<i className="mdi mdi-chevron-down ms-1"></i></span>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem href="#">Locations</DropdownItem>
                                <DropdownItem href="#">Revenue</DropdownItem>
                                <DropdownItem href="#">Join Date</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>*/}
                    <div className="float-end">
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="text-reset cursor-pointer" id="dropdownMenuButton5" caret>
                                <span className="fw-semibold">Sort By:</span> <span className="text-muted">{_.find(periods, ['value', period]).label}<i className="mdi mdi-chevron-down ms-1"></i></span>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                { periods.map(({label, value})=>(
                                  <DropdownItem key={value} onClick={()=>setPeriod(value)}>{label}</DropdownItem>
                                ))
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    <h4 className="card-title mb-4">Top Users</h4>
                    <SimpleBar className="simple-bar">
                        <div className="table-responsive">
                            <Table className="table-borderless table-centered table-nowrap">
                                <tbody>
                                  { data.map((user, index)=>(
                                    <tr key={index}>
                                        <td>{!user.profile_image_uri ? (
                                          <div className="avatar-sm d-inline-block">
                                            <div className="avatar-title bg-soft-primary rounded-circle text-primary">
                                              <i className="mdi mdi-account-circle m-0"></i>
                                            </div>
                                          </div>
                                        ) : (
                                            <img
                                              className="avatar-sm rounded-circle"
                                              src={user.profile_image_uri}
                                              alt=""
                                            />
                                          )}</td>
                                        <td>
                                            <h6 className="font-size-15 mb-1 fw-normal">{user.first_name + ' ' + user.last_name}</h6>
                                            {/*<p className="text-muted font-size-13 mb-0">
                                                <i className="mdi mdi-map-marker"></i> Nevada</p>*/}
                                        </td>
                                        <td><Badge
                                          className={"badge bg-pill text-capitalize font-size-12 bg-soft-" + (user.role=='seller'?'danger':'success')}
                                          color={user.role=='seller'?'danger':'success'}
                                          pill
                                        >
                                          {user.role}
                                        </Badge></td>
                                        <td className="text-muted fw-semibold text-end">
                                            {/*<FeatherIcon icon="trending-up" className="icon-xs icon me-2 text-success" />$250.00*/}
                                            <Link to={`/order/user/${user.id}`}>{numberFormat(user.total, 0)} <small>Orders</small></Link>
                                        </td>
                                    </tr>
                                  ))  }
                                  {
                                    !data.length && <tr><td>No data found.</td></tr>
                                  }
                                </tbody>
                            </Table>
                        </div>
                    </SimpleBar>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default TopUser
