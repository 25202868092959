import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter , Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Modal } from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import api from '../../../utils/Api'
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { getUsers } from "../../../store/contacts/actions"
import contactListColumns from "./contactListColumns"
import { isEmpty } from "lodash"

function Avatar({profileImage, formImage}) {
  const [image, setImage] =  useState(formImage?formImage:(profileImage?URL.createObjectURL(profileImage):null))

  useEffect(()=>{
    if (profileImage)
      setImage(URL.createObjectURL(profileImage))
    else if (formImage && formImage != image)
      setImage(formImage.uri)
  }, [profileImage, formImage])

  return (
    <>
    { image ?
    <div className="avatar-lg d-inline-block me-2" style={{
      background:'url('+(image)+') no-repeat center center / cover',
      borderRadius: '50%'
    }}/>:<div className="avatar-lg d-inline-block me-2">
      <div className="avatar-title column-center bg-soft-primary rounded-circle text-primary">
        <i style={{fontSize: '2rem'}} className="mdi mdi-account-circle m-0"></i>
        <small className="text-center">Choose image</small>
      </div>
    </div>
    }
    </>
  )
}

let deletedId = null

const ContactsList = props => {

  const { users, onGetUsers } = props
  const [userList, setUserList] = useState([])
  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 20,
    totalSize: 0, // replace later with size(users),
    custom: true,
  })

  const [page, setPage] = useState(1)
  const [showEdit, setShowEdit] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const [confirm, setConfirm] = useState('')
  const [profileImage, setProfileImage] = useState(null)
  const [s, setS] = useState('')
  const [editUser, setEditUser] = useState({
    mobile: '', first_name: '', last_name: '', email: '', profile_image_uri: ''
  })

  const searchRef = useRef()

  const {mobile, first_name, last_name, email, profile_image_uri, role} = editUser

  const fetch = (page = 1) => {
    setPage(page)
    api.request('user-verification', ({data, total})=>{
        setUserList(data)
        setPageOptions({...pageOptions, totalSize: total})
    }, {page})
  }

  const { SearchBar } = Search

  // useEffect(() => {
  //   onGetUsers()
  //   setUserList(users)
  // }, [users, onGetUsers])
  //
  // useEffect(() => {
  //   if (!isEmpty(users)) {
  //     setUserList(users)
  //   }
  // }, [users])

  useEffect(()=>{
    fetch()
    api.request('verification-enabled', ({enabled})=>{
      setEnabled(enabled)
    })
  }, [])

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setUserList(
      users.filter(user =>
        Object.keys(user).some(
          key =>
            typeof user[key] === "string" &&
            user[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  var selectRowProp = {
    mode: "checkbox",
    clickToSelect: true,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="User" breadcrumbItem="Pending Verification" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={12}>
                      <div className="float-end mb-2">
                          <label>
                          <input value="1" checked={enabled} onClick={(e)=>{
                            api.request('verification-enabled', ({enabled})=>{
                              setEnabled(enabled)
                            }, api.form({enabled: e.target.checked ? '1':'0'}), {method: 'POST'})
                          }} type="checkbox" />
                          <span className="mx-2">Enable verification in app</span>
                          </label>
                      </div>
                    </Col>
                  </Row>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={userList || []}
                        columns={contactListColumns((id)=>{
                          setConfirm('approve')
                          deletedId = id
                        }, (id)=>{
                          setConfirm('delete')
                          deletedId = id
                        })}
                        bootstrap4
                        //search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive mb-4">
                                  <BootstrapTable
                                  //selectRow={selectRowProp}
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap mb-0"
                                    }
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="float-sm-end">
                              <PaginationListStandalone
                                {...paginationProps}
                                onPageChange={(page)=>fetch(page)}
                                page={page}
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {!!confirm ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnText={confirm == 'approve'?"Approve the verification":"Yes, delete it!"}
          confirmBtnBsStyle={confirm == 'approve'?"success":'danger'}
          //cancelBtnBsStyle="muted"
          onConfirm={() => {
            setConfirm('')
            api.request('user-verification/' + deletedId, ()=>{
              fetch()
            }, {}, {method: confirm == 'approve'?'PATCH':'DELETE'})
          }}
          onCancel={() => setConfirm(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

ContactsList.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
}

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
})

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactsList))
