import React, {useState, useEffect} from "react"
import { Card, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Progress } from "reactstrap"
import api from '../../utils/Api'
import {numberFormat} from '../../utils/Functions'
import _ from 'lodash'
import SimpleBar from "simplebar-react"

const periods = [{
  label: 'Yearly',
  value: 'year'
},{
  label: 'Monthly',
  value: 'month'
},{
  label: 'Weekly',
  value: 'week'
},]

const TopProduct = ({date}) => {
    const [period, setPeriod] = useState(periods[0].value)
    const [total, setTotal] = useState(null)
    const [data, setData] = useState([])
    useEffect(()=>{
      api.request('dashboard/top-categories', (d)=>{
        setTotal(_.sumBy(d,'total'))
        setData(d)
      }, {period, date})
    }, [period, date])

    const progressbars = [
        {
            id: 1,
            title: 'Desktops',
            value: 52,
            color: 'primary'
        },
        {
            id: 2,
            title: 'iPhones',
            value: 45,
            color: 'info'
        },
        {
            id: 3,
            title: 'Android',
            value: 48,
            color: 'success'
        },
        {
            id: 4,
            title: 'Tablets',
            value: 78,
            color: 'warning'
        },
        {
            id: 5,
            title: 'Cables',
            value: 63,
            color: 'purple'
        },
        {
            id: 6,
            title: 'Cables',
            value: 63,
            color: 'danger'
        },
    ]

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="float-end">
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="text-reset cursor-pointer" id="dropdownMenuButton5" caret>
                                <span className="fw-semibold">Sort By:</span> <span className="text-muted">{_.find(periods, ['value', period]).label}<i className="mdi mdi-chevron-down ms-1"></i></span>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                { periods.map(({label, value})=>(
                                  <DropdownItem key={value} onClick={()=>setPeriod(value)}>{label}</DropdownItem>
                                ))
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    <h4 className="card-title mb-4">Top Order Categories</h4>
                    <SimpleBar className="simple-bar">
                    {!!total && data.map((progressbar, key) => (
                        <Row className="align-items-center g-0 mt-3" key={key}>
                            <Col sm={3}>
                                <p className="text-truncate text-capitalize mt-1 mb-0"><i className="mdi mdi-circle-medium text-warning me-2"></i> {progressbar.category} </p>
                            </Col>
                            <Col sm={9}>
                                <div className="mt-1" style={{ height: "6px" }}>
                                    <Progress
                                        value={(progressbar.total*100/total)}
                                        color={progressbars[key].color}
                                        //size="md"
                                        //className="progress-sm"
                                    >
                                    {
                                      numberFormat(progressbar.total*100/total, 1) + '%'
                                    }
                                    </Progress>
                                </div>
                            </Col>
                        </Row>
                    ))}
                    </SimpleBar>
                    {
                      total === 0 && <p>No orders found.</p>
                    }
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default TopProduct
