import React from "react"
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  Badge
} from "reactstrap"
import { Link } from "react-router-dom"
import {numberFormat} from '../../../utils/Functions'
import moment from 'moment'

const stateColors = {
  active: 'info',
  completed: 'success',
  cancelled: 'warning'
}

const contactListColumns = (onEdit = ()=>{}, onDelete = ()=>{}, onSort = ()=>{}) => [
  {
    text: "User Name",
    dataField: "user",
    sort: false,
    formatter: (cellContent, order) => (
      <>
        {!cellContent.profile_image_uri ? (
          <div className="avatar-xs d-inline-block me-2">
            <div className="avatar-title bg-soft-primary rounded-circle text-primary">
              <i className="mdi mdi-account-circle m-0"></i>
            </div>
          </div>
        ) : (
            <img
              className="avatar-xs rounded-circle me-2"
              src={cellContent.profile_image_uri}
              alt=""
            />
          )}
        <Link to="#" className="text-body">
          {cellContent.first_name}
        </Link>
      </>
    ),
  },
  {
    text: "Account ID",
    dataField: "user.account_id",
    sort: false,
  },
  {
    text: "Posting ID",
    dataField: "posting_id",
    sort: false,
  },
  {
    text: "Transaction ID",
    dataField: "transaction_id",
    sort: true,
    sortCaret: (order, column) => {
      return <span className={'mx-1 sort-column '+order}></span>
    },
    onSort: onSort
  },
  {
    text: "Type",
    dataField: "user",
    sort: false,
    formatter: (cellContent, order) => (
      <>
        <Badge
          className={"badge bg-pill font-size-12 bg-soft-" + (cellContent.role=='seller'?'danger':'success')}
          color={cellContent.role=='seller'?'danger':'success'}
          pill
        >
          {cellContent.role=='seller'?'Ask':'Bid'}
        </Badge>
      </>
    ),
  },
  {
    text: "Counterparty ID",
    dataField: "counterparty.account_id",
    sort: false,
  },
  {
    text: "Initial Bid Quantity",
    dataField: "initial_bid_quantity",
    sort: false,
    formatter: (cellContent, order) => (
      <span>{cellContent?numberFormat(cellContent)+'kg':''}</span>
    )
  },
  {
    dataField: "price",
    text: "Posting Price/kg",
    sort: false,
    formatter: (cellContent, order) => (
      <span>฿{numberFormat(cellContent)}</span>
    ),
    // sortCaret: (order, column) => {
    //   return <span className={'mx-1 sort-column '+order}></span>
    // },
    // onSort: onSort
  },
  {
    dataField: "initial_bid_price",
    text: "Initial Bid Price/kg",
    sort: false,
    formatter: (cellContent, order) => (
      <span>{cellContent?'฿'+numberFormat(cellContent):''}</span>
    )
  },
  {
    dataField: "closing_date",
    text: "Closing Date",
    sort: false,
  },
  {
    dataField: "closing_quantity",
    text: "Closing Quantity",
    sort: false,
    formatter: (cellContent, order) => (
      <span>{cellContent?numberFormat(cellContent)+'kg':''}</span>
    )
  },
  {
    dataField: "closing_price",
    text: "Closing Price",
    sort: false,
    formatter: (closing_price)=>(
        <span>{closing_price?'฿'+numberFormat(closing_price):''}</span>
    )
  },
  {
    dataField: "reason",
    text: "Reason for Close",
    sort: false,
  },
  {
    dataField: "as_of_date",
    text: "As of Date",
    sort: true,
    sortCaret: (order, column) => {
      return <span className={'mx-1 sort-column '+order}></span>
    },
    onSort: (f, o)=>onSort('updated_at', o)
  },
  // {
  //   dataField: "menu",
  //   isDummyField: true,
  //   text: "Action",
  //   formatter: (cellContent, user) => (
  //     <ul className="list-inline font-size-20 contact-links mb-0">
  //       <li className="list-inline-item">
  //         <Link onClick={()=>onEdit(user)} className="px-2 text-primary"><i className="uil uil-pen font-size-18"></i></Link>
  //       </li>
  //       { user.role == 'seller' &&
  //       <li className="list-inline-item">
  //         <Link onClick={()=>onDelete(user.id)} className="px-2 text-danger"><i className="uil uil-trash-alt font-size-18"></i></Link>
  //       </li>
  //       }
  //       {/*<li className="list-inline-item dropdown">
  //         <UncontrolledDropdown>
  //           <DropdownToggle tag="a" className="text-muted font-size-18 px-2" caret>
  //             <i className="uil uil-ellipsis-v"></i>
  //           </DropdownToggle>
  //           <DropdownMenu className="dropdown-menu-end">
  //             <DropdownItem href="#">Action</DropdownItem>
  //             <DropdownItem href="#">Another action</DropdownItem>
  //             <DropdownItem href="#">Something else here</DropdownItem>
  //           </DropdownMenu>
  //         </UncontrolledDropdown>
  //       </li>*/}
  //     </ul>
  //   ),
  //},
]

export default contactListColumns
