export const getLimitedNumber = (number, limit) => {
    if (number <= limit) {
        return number;
    }

    return limit + '+';
}


export function fileNameAndExt(str){
  var file = str.split('/').pop();

  return file.substr(0,file.lastIndexOf('.')) +'.'+file.substr(file.lastIndexOf('.')+1,file.length);
}

export function numberFormat(num, hideDecimalWhenInteger = true, zeroText = '0') {
    var tester = parseFloat(num);

    if (num === 0 || num === null || num === undefined || num === '') {
        return zeroText;
    }

    if (tester > Math.floor(tester) ) {
        num = tester;
        num = num.toFixed(2);
    } else if (hideDecimalWhenInteger) {
        if (num != '' && num != null){
            num = Math.floor(num);
        }
    }

    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function currencyFormat(num, currencyText = '฿') {
  return currencyText + numberFormat(num, 2, '0')
};


export function numberInRange(number, min, max) {
    return Math.max(Math.min(number, max), min);
}
