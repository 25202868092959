import React, {useEffect, useState} from "react"
import ReactApexChart from "react-apexcharts"
import { Card, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle } from "reactstrap"
import CountUp from 'react-countup';
import api from '../../utils/Api'

const periods = [{
  label: 'Yearly',
  value: 'year'
},{
  label: 'Monthly',
  value: 'month'
},{
  label: 'Weekly',
  value: 'week'
},]

const SalesAnalyticsChart = ({date}) => {
  const [data, setData] = useState({total_order: [], completed: [], labels: [], cancelled: []})
  const [period, setPeriod] = useState(periods[0].value)

  useEffect(()=>{
    api.request('dashboard/order-chart',(d)=>{
      setData(d)
    }, {period, date})
  }, [period, date])

    const series = [{
        name: 'Completed Orders',
        type: 'column',
        data: data.completed
    }, {
        name: 'Cancelled Orders',
        type: 'column',
        data: data.cancelled
    },{
        name: 'Total Orders',
        type: 'area',
        data: data.total_order
    }]

    const options = {
        chart: {
            stacked: true,
            toolbar: {
                show: !1
            }
        },
        stroke: {
            width: [0, 0, 4],
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '30%'
            }
        },
        colors: ['#28a745', '#ffc107', '#5b73e8'],

        fill: {
            opacity: [1, 1, 0.2],
            gradient: {
                inverseColors: !1,
                shade: 'light',
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
            }
        },
        labels: data.labels,
        markers: {
            size: 0
        },

        xaxis: {
            //type: 'datetime'
        },
        yaxis: {
            title: {
                text: 'No. of Order',
            },
        },
        tooltip: {
            shared: !0,
            intersect: !1,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0) + " orders";
                    }
                    return y;

                }
            }
        },
        grid: {
            borderColor: '#f1f1f1'
        }
    }

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    {/*<div className="float-end">
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="text-reset" id="dropdownMenuButton5" caret href="#">
                                <span className="fw-semibold">Sort By:</span> <span className="text-muted">Yearly<i className="mdi mdi-chevron-down ms-1"></i></span>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem href="#">Monthly</DropdownItem>
                                <DropdownItem href="#">Yearly</DropdownItem>
                                <DropdownItem href="#">Weekly</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>*/}
                    <CardTitle className="mb-4 h4">Order Chart</CardTitle>
                    {/*<div className="mt-1">
                        <ul className="list-inline main-chart mb-0">
                            <li className="list-inline-item chart-border-left me-0 border-0">
                                <h3 className="text-primary"><span><CountUp end={2371} separator="," prefix="$" /></span><span className="text-muted d-inline-block font-size-15 ms-3">Income</span></h3>
                            </li>{" "}
                            <li className="list-inline-item chart-border-left me-0">
                                <h3><span data-plugin="counterup"><CountUp end={258} /></span><span className="text-muted d-inline-block font-size-15 ms-3">Sales</span>
                                </h3>
                            </li>{" "}
                            <li className="list-inline-item chart-border-left me-0">
                                <h3><span data-plugin="counterup"><CountUp end={3.6} decimals={1} suffix="%" /></span><span className="text-muted d-inline-block font-size-15 ms-3">Conversation Ratio</span></h3>
                            </li>
                        </ul>
                    </div>*/}

                    <div className="mt-3">
                        <ReactApexChart
                            options={options}
                            series={series}
                            height="339"
                            type="line"
                            className="apex-charts"
                        />
                    </div>
                </CardBody>
            </Card>

        </React.Fragment>
    )
}

export default SalesAnalyticsChart
