import axios from 'axios';
import {fileNameAndExt} from './Functions';
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const CancelToken = axios.CancelToken;

const dev = !!window.location.host.toString().match(/localhost/g)

const baseDomain = dev?'192.168.1.34:3333':'api.nadee.me'
const scheme = dev?'http://':'https://'
const wsScheme = dev?'ws://':'wss://'


const baseUrl = scheme + baseDomain+'/admin/';
const baseApiUrl = baseUrl;

const requester = axios.create({
  baseURL: baseApiUrl
});

export const showCommonError = function (error) {
    if (error.response && error.response.data) {
        var msg;
        switch (error.response.status) {
            case 401:
                msg = 'Session is invalid.';
            break;
            case 403:
                msg = 'No access.';
            break;
            case 404:
                msg = 'Record not found.';
            break;
            case 500:
                msg = 'Internal Server  Error';
            break;
            default:
                if (error.response.data.error) {
                    msg = error.response.data.error.message;
                } else {
                    msg = error.response.data.message;
                }
            break;
        }

        toastr.error(msg, 'Error !')
    }
};

const form = (params, files = null) => {
    var formData = new FormData();

    for (var key in params) {
        if (Array.isArray(params[key])) {
            for (var d in params[key]) {
                if ( typeof params[key][d] == 'object') {
                  for (var e in params[key][d]) {
                    formData.append(key + '['+d+']['+e+']', params[key][d][e])
                  }
                } else {
                  formData.append(key + '[]', params[key][d]);
                }
            }
        } else {
            formData.append(key, typeof params[key] == 'object'?JSON.stringify(params[key]):params[key]);
        }
    }

    if (files) {
        for (var i in files) {
            if (Array.isArray(files[i])){
                for (var j in files[i]) {
                    if (files[i][j] instanceof File) {
                      formData.append(i + '[]', files[i][j]);
                    } else {
                      const uri = files[i][j].uri?files[i][j].uri:files[i][j].path;
                      const image = {
                          uri: uri,
                          type: files[i][j].type?files[i][j].type:files[i][j].mime,
                          name: files[i][j].name ? files[i][j].name : fileNameAndExt(uri)
                      };

                      formData.append(i + '[]', image);
                    }
                }
            } else {
                if (files[i] instanceof File) {
                  formData.append(i, files[i]);
                } else {
                  const uri = files[i].uri?files[i].uri:files[i].path;
                  const image = {
                      uri: uri,
                      type: files[i].type?files[i].type:files[i].mime,
                      name: files[i].name ? files[i].name : fileNameAndExt(uri)
                  };

                  formData.append(i, image);
                }
            }
        }
    }

    formData = modifier(formData, params);

    return formData;
}

const getToken = () => {
    return localStorage.getItem('token');
}

const getHeader = () => {
    const token = getToken();

    if (!token) {
        return {};
    }

    return {'Authorization': 'Bearer ' + token};
}

const api =  {
     requestPromise: (url, params = {}, config, options) => {
         config = {...{method:'GET'}, ...config}
         options = {...{showError: false}, ...options}

         const cancelSource = CancelToken.source();
         const cancelToken = cancelSource.token
         const {method} = config
         const {showError} = options

         return requester(Object.assign({
             method,
             url,
             headers: getHeader(),
             cancelToken
         }, method == 'GET'?{params: params}:{data:params}))
     },
     request: (url, callback = ()=>{}, params = {}, config, options) => {
         config = {...{method:'GET'}, ...config}
         const {method} = config

         options = {...{showError: method!='GET'}, ...options}

         const cancelSource = CancelToken.source();
         const cancelToken = cancelSource.token
         const {showError} = options

         requester(Object.assign({
             method,
             url,
             headers: getHeader(),
             cancelToken
         }, method == 'GET'?{params: params}:{data:params})).then((response)=>{
             callback(response.data, true);
         }).catch((error) => {

             if (!error.response) {
                 callback(error, false)
                 return;
             }

             if (showError) {
                 showCommonError(error);
             }

             callback(error, false)
         });

         return cancelSource
     },
     showCommonError: showCommonError,
     form: form,
     url: {
         baseDomain,
         base: baseUrl,
         baseApi: baseApiUrl,
         ws: wsScheme + baseDomain
     },
     getToken
}

function modifier(formData, params) {
    //append something

    return formData;
}

export default api;
