import React from "react"
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  Badge
} from "reactstrap"
import { Link } from "react-router-dom"
import moment from 'moment'

const contactListColumns = (onEdit = ()=>{}, onDelete = ()=>{}) => [
  {
    text: "User Name",
    dataField: "name",
    sort: false,
    formatter: (cellContent, user) => (
      <>
        {!user.profile_image_uri ? (
          <div className="avatar-xs d-inline-block me-2">
            <div className="avatar-title bg-soft-primary rounded-circle text-primary">
              <i className="mdi mdi-account-circle m-0"></i>
            </div>
          </div>
        ) : (
            <img
              className="avatar-xs rounded-circle me-2"
              src={user.profile_image_uri}
              alt=""
            />
          )}
        <Link to="#" className="text-body">
          {user.first_name}
        </Link>
      </>
    ),
  },
  {
    text: "Registration ID",
    dataField: "registration_id",
    sort: false,
  },
  {
    text: "Account ID",
    dataField: "account_id",
    sort: false,
  },
  {
    text: "Role",
    dataField: "role",
    sort: false,
    formatter: (role, user) => (
      <Badge
        className={"badge bg-pill text-capitalize font-size-12 bg-soft-" + (role=='seller'?'danger':'success')}
        color={role=='seller'?'danger':'success'}
        pill
      >
        {role}
      </Badge>
    ),
  },
  {
    text: "Location",
    dataField: "location",
    sort: false
  },
  {
    dataField: "mobile",
    text: "Mobile",
    sort: false,
  },
  {
    dataField: "email",
    text: "Email",
    sort: false,
  },
  {
    dataField: "__meta__",
    text: "No. of Orders",
    sort: false,
    formatter: (cellContent, user) => (
      cellContent.orders_count?<Link to={`/order/user/${user.id}`}>{cellContent.orders_count}</Link>:'-'
    )
  },
  {
    text: "Registration Date",
    dataField: "registration_date",
    sort: false
  },
  {
    text: "First Posting Date",
    dataField: "first_posting_date",
    sort: false
  },
  {
    text: "Last Posting Date",
    dataField: "last_posting_date",
    sort: false
  },
  {
    text: "Last Login Date",
    dataField: "last_login_date",
    sort: false,
  },
  {
    text: "As of Date",
    dataField: "as_of_date",
    sort: false,
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, user) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item">
          <Link onClick={()=>onEdit(user)} className="px-2 text-primary"><i className="uil uil-pen font-size-18"></i></Link>
        </li>
        <li className="list-inline-item">
          <Link onClick={()=>onDelete(user.id)} className="px-2 text-danger"><i className="uil uil-trash-alt font-size-18"></i></Link>
        </li>
        {/*<li className="list-inline-item dropdown">
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="text-muted font-size-18 px-2" caret>
              <i className="uil uil-ellipsis-v"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem href="#">Action</DropdownItem>
              <DropdownItem href="#">Another action</DropdownItem>
              <DropdownItem href="#">Something else here</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </li>*/}
      </ul>
    ),
  },
]

export default contactListColumns
