import React from "react"
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  Badge
} from "reactstrap"
import { Link } from "react-router-dom"

const contactListColumns = (onEdit = ()=>{}, onDelete = ()=>{}) => [
  {
    text: "Name",
    dataField: "name",
    sort: false,
    formatter: (cellContent, {user}) => (
      <>
        {!user.profile_image_uri ? (
          <div className="avatar-xs d-inline-block me-2">
            <div className="avatar-title bg-soft-primary rounded-circle text-primary">
              <i className="mdi mdi-account-circle m-0"></i>
            </div>
          </div>
        ) : (
            <img
              className="avatar-xs rounded-circle me-2"
              src={user.profile_image_uri}
              alt=""
            />
          )}
        <Link to="#" className="text-body">
          {user.first_name}
        </Link>
      </>
    ),
  },
  {
    text: "Role",
    dataField: "user.role",
    sort: false,
    formatter: (role, user) => (
      <Badge
        className={"badge bg-pill text-capitalize font-size-12 bg-soft-" + (role=='seller'?'danger':'success')}
        color={role=='seller'?'danger':'success'}
        pill
      >
        {role}
      </Badge>
    ),
  },
  {
    dataField: "user.mobile",
    text: "Mobile",
    sort: false,
  },
  {
    dataField: "user.email",
    text: "Email",
    sort: false,
  },
  {
    dataField: "user",
    text: "Images",
    sort: false,
    formatter: (cellContent, user) => (
      <div className="row-vcenter">
        <a style={{marginRight: '1rem'}} href={user.id_card_front_uri} target="_blank"><img style={{maxWidth: 100, maxHeight: 100}} src={user.id_card_front_uri} /></a>
        <a href={user.id_card_back_uri} target="_blank"><img style={{maxWidth: 100, maxHeight: 100}} src={user.id_card_back_uri} /></a>
      </div>

    )
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, user) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item">
          <Link onClick={()=>onEdit(user.id)} className="px-2 text-success"><i className="uil uil-check-circle font-size-20"></i></Link>
        </li>
        <li className="list-inline-item">
          <Link onClick={()=>onDelete(user.id)} className="px-2 text-danger"><i className="uil uil-trash-alt font-size-18"></i></Link>
        </li>
        {/*<li className="list-inline-item dropdown">
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="text-muted font-size-18 px-2" caret>
              <i className="uil uil-ellipsis-v"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem href="#">Action</DropdownItem>
              <DropdownItem href="#">Another action</DropdownItem>
              <DropdownItem href="#">Something else here</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </li>*/}
      </ul>
    ),
  },
]

export default contactListColumns
