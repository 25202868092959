import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter , Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Modal, Button } from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import api from '../../../utils/Api'
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import SweetAlert from "react-bootstrap-sweetalert"
import csvDownload from 'json-to-csv-export'
import moment from 'moment'
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { getUsers } from "../../../store/contacts/actions"
import contactListColumns from "./contactListColumns"
import { isEmpty } from "lodash"

function Avatar({profileImage, formImage}) {
  const [image, setImage] =  useState(formImage?formImage:(profileImage?URL.createObjectURL(profileImage):null))

  useEffect(()=>{
    if (profileImage)
      setImage(URL.createObjectURL(profileImage))
    else if (formImage && formImage != image)
      setImage(formImage.uri)
  }, [profileImage, formImage])

  return (
    <>
    { image ?
    <div className="avatar-lg d-inline-block me-2" style={{
      background:'url('+(image)+') no-repeat center center / cover',
      borderRadius: '50%'
    }}/>:<div className="avatar-lg d-inline-block me-2">
      <div className="avatar-title column-center bg-soft-primary rounded-circle text-primary">
        <i style={{fontSize: '2rem'}} className="mdi mdi-account-circle m-0"></i>
        <small className="text-center">Choose image</small>
      </div>
    </div>
    }
    </>
  )
}

let deletedId = null

const ContactsList = props => {

  const { users, onGetUsers } = props
  const [userList, setUserList] = useState([])
  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 10,
    totalSize: 0, // replace later with size(users),
    custom: true,
  })

  const [page, setPage] = useState(1)
  const [showEdit, setShowEdit] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [profileImage, setProfileImage] = useState(null)
  const [s, setS] = useState('')
  const [editUser, setEditUser] = useState({
    mobile: '', first_name: '', last_name: '', email: '', profile_image_uri: ''
  })
  const [exporting, setExporting] = useState(false)

  const searchRef = useRef()

  const {mobile, first_name, last_name, email, profile_image_uri, role} = editUser

  const fetch = (page = 1) => {
    setPage(page)
    api.request('user', ({data, total, perPage})=>{
        setUserList(data)
        setPageOptions({...pageOptions, totalSize: total, sizePerPage: perPage})
    }, {page, role: ['seller', 'buyer'], s})
  }

  const { SearchBar } = Search

  // useEffect(() => {
  //   onGetUsers()
  //   setUserList(users)
  // }, [users, onGetUsers])
  //
  // useEffect(() => {
  //   if (!isEmpty(users)) {
  //     setUserList(users)
  //   }
  // }, [users])

  useEffect(()=>{
    if (!s && searchRef.current) {
      searchRef.current.value = ''
    }
    fetch()
  }, [s])

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, state) => {
    alert(type)
  }

  var selectRowProp = {
    mode: "checkbox",
    clickToSelect: true,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="User List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={userList || []}
                        columns={contactListColumns((user)=>{
                          setEditUser(user)
                          setShowEdit(true)
                        }, (id)=>{
                          setConfirm(true)
                          deletedId = id
                        })}
                        bootstrap4
                        //search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="row mb-2">
                              <Col md={6} className="d-flex">
                                <div className="mb-3">
                                  <Link onClick={()=>{
                                    setEditUser({})
                                    setShowEdit(true)
                                  }} className="btn btn-success waves-effect waves-light"><i className="mdi mdi-plus me-2"></i> Add New Seller</Link>
                                </div>
                                <Button color="primary" className="mx-3 mb-3" disabled={exporting} onClick={(e)=>{
                                  e.preventDefault()
                                  setExporting(true)
                                  api.request('user', (r, s)=>{
                                    if (!s) {
                                      setExporting(false)
                                      return
                                    }
                                    csvDownload(r.map((i)=>({
                                      "User Name": i.first_name + (i.last_name?(' '+i.last_name): ''),
                                      "Registration ID": i.registration_id,
                                      "Account ID": i.account_id,
                                      "Role": i.role=='seller'?'Seller':'Buyer',
                                      Location: i.location,
                                      Mobile: i.mobile,
                                      Email: i.email,
                                      "No. of Orders": i.__meta__.orders_count,
                                      "Registration Date": i.registration_date,
                                      "First Posting Date": i.first_posting_date,
                                      "Last Posting Date": i.last_posting_date,
                                      "Last Login Date": i.last_login_date,
                                      "As of Date": i.as_of_date,
                                    })), "users_"+moment().format('YYYY-MM-DD')+".csv")
                                    setExporting(false)
                                  }, {get_all: 1, role: ['seller', 'buyer'], s})

                                }}>
                                  Export as CSV
                                </Button>
                              </Col>
                              <Col md={6}>
                                <form onSubmit={(e)=>{
                                  e.preventDefault()
                                  const formData = new FormData(e.target)
                                  setS(formData.get('s'))
                                }}>
                                  <div className="form-inline float-md-end mb-3">
                                    <div className="search-box ms-2" style={{width: 280}}>
                                      <div className="row-vcenter position-relative form-control">
                                        <input  id="search-bar-0" type="text" name="s" aria-labelledby="search-bar-0-label"
                                        class="border-0 form-control p-0" style={{borderRadius: 0}} ref={searchRef} placeholder="Search by email, name, mobile" />
                                        { !!s &&
                                          <i onClick={()=>setS('')} className="mdi mdi-close cursor-pointer"></i>
                                        }
                                        <i className="mdi mdi-magnify search-icon"></i>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </Col>
                              {/*<Col md={6}>
                                <div className="form-inline float-md-end mb-3">
                                  <div className="search-box ms-2">
                                    <div className="position-relative">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      <i className="mdi mdi-magnify search-icon"></i>
                                    </div>
                                  </div>
                                </div>
                              </Col>*/}
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive mb-4">
                                  <BootstrapTable
                                  //selectRow={selectRowProp}
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap mb-0"
                                    }
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="float-sm-end">
                              <PaginationListStandalone
                                {...paginationProps}
                                onPageChange={(page)=>fetch(page)}
                                page={page}
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        size="md"
        isOpen={showEdit}
        toggle={() => {
          setShowEdit(!showEdit)
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add/Edit Seller
            </h5>
          <button
            type="button"
            onClick={() => {
              setShowEdit(false)
              setEditUser({})
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="custom-file-input-container row-center mb-4">
            <Avatar profileImage={profileImage} formImage={profile_image_uri} />
            <input type="file" onChange={(e)=>setProfileImage(e.target.files[0])} />
          </div>
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-3 col-form-label"
            >
              First Name
            </label>
            <div className="col-md-9">
              <input
                className="form-control"
                type="text"
                value={first_name}
                onChange={(e)=>setEditUser({...editUser, first_name: e.target.value})}
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-3 col-form-label"
            >
              Last Name
            </label>
            <div className="col-md-9">
              <input
                className="form-control"
                type="text"
                value={last_name}
                onChange={(e)=>setEditUser({...editUser, last_name: e.target.value})}
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-3 col-form-label"
            >
              Mobile
            </label>
            <div className="col-md-9">
              <input
                className="form-control"
                type="tel"
                value={mobile}
                onChange={(e)=>setEditUser({...editUser, mobile: e.target.value})}
              />
            </div>
          </Row>
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-3 col-form-label"
            >
              Email
            </label>
            <div className="col-md-9">
              <input
                className="form-control"
                type="email"
                value={email}
                onChange={(e)=>setEditUser({...editUser, email: e.target.value})}
              />
            </div>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setShowEdit(false)
              setEditUser({})
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
            </button>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={()=>{
              let url = 'user'
              let method = 'POST'

              if (editUser.id) {
                url = 'user/'+ editUser.id
                method = 'PATCH'
              }

              api.request(url, (r, s)=>{
                if (s) {
                  toastr.success('Save completed !', 'Successfully')
                  setShowEdit(false)
                  setEditUser({})
                  fetch()
                }
              }, api.form({...editUser, role: role?role:'seller'}, profileImage ? {profile_image: profileImage}: {}), {method})
            }}
          >
            Save changes
            </button>
        </div>
      </Modal>
      {confirm ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          //cancelBtnBsStyle="warning"
          onConfirm={() => {
            setConfirm(false)
            api.request('user/' + deletedId, ()=>{
              fetch()
            }, {}, {method: 'DELETE'})
          }}
          onCancel={() => setConfirm(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

ContactsList.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
}

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
})

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactsList))
