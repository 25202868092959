import React from "react"
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  Badge
} from "reactstrap"
import { Link } from "react-router-dom"
import {numberFormat} from '../../../utils/Functions'
import moment from 'moment'

const stateColors = {
  active: 'info',
  completed: 'success',
  cancelled: 'warning'
}

const contactListColumns = (onEdit = ()=>{}, onDelete = ()=>{}, onSort = ()=>{}) => [
  {
    text: "User Name",
    dataField: "user",
    sort: false,
    formatter: (cellContent, order) => (
      <>
        {!cellContent.profile_image_uri ? (
          <div className="avatar-xs d-inline-block me-2">
            <div className="avatar-title bg-soft-primary rounded-circle text-primary">
              <i className="mdi mdi-account-circle m-0"></i>
            </div>
          </div>
        ) : (
            <img
              className="avatar-xs rounded-circle me-2"
              src={cellContent.profile_image_uri}
              alt=""
            />
          )}
        <Link to="#" className="text-body">
          {cellContent.first_name}
        </Link>
      </>
    ),
  },
  {
    text: "Account ID",
    dataField: "user.account_id",
    sort: false,
  },
  {
    text: "Posting ID",
    dataField: "posting_id",
    sort: false,
  },
  {
    text: "Type",
    dataField: "user",
    sort: false,
    formatter: (cellContent, order) => (
      <>
        <Badge
          className={"badge bg-pill font-size-12 bg-soft-" + (cellContent.role=='seller'?'danger':'success')}
          color={cellContent.role=='seller'?'danger':'success'}
          pill
        >
          {cellContent.role=='seller'?'Sell':'Buy'}
        </Badge>
      </>
    ),
  },
  {
    text: "Title",
    dataField: "title",
    sort: false,
  },
  {
    text: "Location",
    dataField: "address",
    sort: false,
  },
  {
    dataField: "price",
    text: "Price/kg",
    sort: true,
    formatter: (cellContent, order) => (
      <span>฿{numberFormat(cellContent)}</span>
    ),
    sortCaret: (order, column) => {
      return <span className={'mx-1 sort-column '+order}></span>
    },
    onSort: onSort
  },
  {
    dataField: "amount",
    text: "Quantity",
    sort: true,
    formatter: (cellContent, order) => (
      <span>{numberFormat(cellContent)}kg</span>
    ),
    sortCaret: (order, column) => {
      return <span className={'mx-1 sort-column '+order}></span>
    },
    onSort: onSort
  },
  {
    dataField: "remaining_amount",
    text: "Remaining Quantity",
    sort: false,
    formatter: (remaining_amount, order) => (
      <span>{(remaining_amount!==''?numberFormat(remaining_amount):'')+(remaining_amount!==''?'kg':'')}</span>
    )
  },
  {
    dataField: "category",
    text: "Category",
    sort: false,
    formatter: (cellContent, order) => (
      <span className="text-capitalize">{cellContent}</span>
    )
  },
  {
    dataField: "state",
    text: "Current Status",
    sort: false,
    formatter: (cellContent, row) => (
      cellContent == 'completed' ?
      <Badge
        className={"badge text-capitalize bg-pill font-size-12 bg-soft-" + stateColors[row.state]}
        color={stateColors[row.state]}
        pill
      >
        {row.state}
      </Badge>
      :
      <UncontrolledDropdown className="form-inline mb-3">
          <DropdownToggle tag="a" className="text-reset cursor-pointer" id="dropdownMenuButton5" caret>
            <Badge
              className={"badge text-capitalize bg-pill font-size-12 bg-soft-" + stateColors[row.state]}
              color={stateColors[row.state]}
              pill
            >
              {row.state}<i className="mdi mdi-chevron-down ms-1"></i>
            </Badge>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem onClick={()=>onEdit(row, cellContent == 'active'?'cancelled':'active')}>{cellContent == 'active'?'Cancel':'Activate'}</DropdownItem>
          </DropdownMenu>
      </UncontrolledDropdown>

    )
  },
  {
    dataField: "origination_date",
    text: "Origination Date",
    sort: true,
    sortCaret: (order, column) => {
      return <span className={'mx-1 sort-column '+order}></span>
    },
    onSort: (f, o)=>onSort('created_at', o)
  },
  {
    dataField: "as_of_date",
    text: "As of Date",
    sort: true,
    sortCaret: (order, column) => {
      return <span className={'mx-1 sort-column '+order}></span>
    },
    onSort: (f, o)=>onSort('updated_at', o)
  },
  {
    dataField: "closing_date",
    text: "Closing Date",
    sort: false,
  },
  {
    dataField: "closing_price",
    text: "Closing Price",
    sort: false,
    formatter: (closing_price)=>(
        <span>{closing_price?'฿'+numberFormat(closing_price):''}</span>
    )
  },
  {
    dataField: "reason",
    text: "Reason for Close",
    sort: false,
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, order) => (
      order.state == 'active' ?
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item">
          <Link onClick={()=>onDelete(order)} className="px-2 text-danger"><i className="uil uil-trash-alt font-size-18"></i></Link>
        </li>
      </ul> : null
    )
  }
]

export default contactListColumns
