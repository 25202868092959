import React from "react"
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  Badge
} from "reactstrap"
import { Link } from "react-router-dom"

import {numberFormat} from '../../utils/Functions'
import moment from 'moment'

const stateColors = {
  active: 'info',
  completed: 'success',
  cancelled: 'warning'
}

const contactListColumns = (onEdit = ()=>{}, onDelete = ()=>{}) => [
  {
    text: "Order Owner",
    dataField: "user",
    sort: false,
    formatter: (cellContent, order) => (
      <>
        {!cellContent.profile_image_uri ? (
          <div className="avatar-xs d-inline-block me-2">
            <div className="avatar-title bg-soft-primary rounded-circle text-primary">
              <i className="mdi mdi-account-circle m-0"></i>
            </div>
          </div>
        ) : (
            <img
              className="avatar-xs rounded-circle me-2"
              src={cellContent.profile_image_uri}
              alt=""
            />
          )}
        <Link to="#" className="text-body">
          {cellContent.first_name}
        </Link>
      </>
    ),
  },
  {
    text: "Account ID",
    dataField: "user.account_id",
    sort: false,
  },
  {
    text: "Posting ID",
    dataField: "posting_id",
    sort: false,
  },
  {
    text: "Type",
    dataField: "user",
    sort: false,
    formatter: (cellContent, order) => (
      <>
        <Badge
          className={"badge bg-pill font-size-12 bg-soft-" + (cellContent.role=='seller'?'danger':'success')}
          color={cellContent.role=='seller'?'danger':'success'}
          pill
        >
          {cellContent.role=='seller'?'Sell':'Buy'}
        </Badge>
      </>
    ),
  },
  {
    text: "Title",
    dataField: "title",
    sort: false,
  },
  {
    text: "Detail",
    dataField: "description",
    sort: false,
    formatter: (col, row) => {
      return <span style={{display: 'block',
       maxWidth: 250,
       whiteSpace: 'break-spaces'}}>{col}</span>
    }
  },
  {
    text: "Location",
    dataField: "address",
    sort: false,
  },
  {
    dataField: "price",
    text: "Price/kg",
    sort: false,
    formatter: (cellContent, order) => (
      <span>฿{numberFormat(cellContent)}</span>
    ),
  },
  {
    dataField: "amount",
    text: "Quantity",
    sort: false,
    formatter: (cellContent, order) => (
      <span>{numberFormat(cellContent)}kg</span>
    ),
  },
  {
    dataField: "category",
    text: "Category",
    sort: false,
    formatter: (cellContent, order) => (
      <span className="text-capitalize">{cellContent}</span>
    )
  },
  {
    dataField: "updated_at",
    text: "Order Date",
    sort: false,
    formatter: (field) => (moment(field).format('ll'))
  },
  {
    dataField: "__meta__",
    text: "Total Reports",
    sort: false,
    formatter: (cellContent, order) => (
      <span className="float-end">{numberFormat(cellContent.reportedByUsers_count)}</span>
    )
  },
  {
    dataField: "state",
    text: "Current Status",
    sort: false,
    formatter: (cellContent, row) => (
      cellContent == 'completed' ?
      <Badge
        className={"badge text-capitalize bg-pill font-size-12 bg-soft-" + stateColors[row.state]}
        color={stateColors[row.state]}
        pill
      >
        {row.state}
      </Badge>
      :
      <UncontrolledDropdown className="form-inline mb-3">
          <DropdownToggle tag="a" className="text-reset cursor-pointer" id="dropdownMenuButton5" caret>
            <Badge
              className={"badge text-capitalize bg-pill font-size-12 bg-soft-" + stateColors[row.state]}
              color={stateColors[row.state]}
              pill
            >
              {row.state}<i className="mdi mdi-chevron-down ms-1"></i>
            </Badge>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem onClick={()=>onEdit(row, cellContent == 'active'?'cancelled':'active')}>{cellContent == 'active'?'Cancel':'Activate'}</DropdownItem>
          </DropdownMenu>
      </UncontrolledDropdown>

    )
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, order) => (
      order.state == 'active' ?
      <ul className="list-inline contact-links mb-0">
        <li className="list-inline-item">
          <Link onClick={()=>onDelete(order.id)} className="px-2 text-danger">Ignore reports</Link>
        </li>
      </ul> : null
    )
  }
]

export default contactListColumns
