import React, {useState, useEffect} from "react"
import { Card, CardBody, DropdownToggle, DropdownMenu, DropdownItem, Table, UncontrolledDropdown, Badge } from "reactstrap"
import { Link } from "react-router-dom"
import FeatherIcon from "feather-icons-react"
//Simple bar
import SimpleBar from "simplebar-react"

import api from '../../utils/Api'
import {numberFormat} from '../../utils/Functions'
import _ from 'lodash'

const periods = [{
  label: 'Yearly',
  value: 'year'
},{
  label: 'Monthly',
  value: 'month'
},{
  label: 'Weekly',
  value: 'week'
},]

const states = [{
  label: 'All',
  value: ''
},{
  label: 'Initial',
  value: 'active'
},{
  label: 'Completed',
  value: 'completed'
}]

const TopUser = ({date}) => {
  const [data, setData] = useState([])
  const [period, setPeriod] = useState(periods[0].value)
  const [state, setState] = useState(states[0].value)

  useEffect(()=>{
     api.request('dashboard/top-price-categories',(d)=>{
       setData(d)
     }, {period, date, state})
  }, [period, date, state])

  return (
          <React.Fragment>
              <Card>
                  <CardBody>
                      <div className="float-end">
                          <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="text-reset cursor-pointer" id="dropdownMenuButton5" caret>
                                  <span className="fw-semibold">Sort By:</span> <span className="text-muted">{_.find(periods, ['value', period]).label}<i className="mdi mdi-chevron-down ms-1"></i></span>
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                  { periods.map(({label, value})=>(
                                    <DropdownItem key={value} onClick={()=>setPeriod(value)}>{label}</DropdownItem>
                                  ))
                                  }
                              </DropdownMenu>
                          </UncontrolledDropdown>
                      </div>
                      <div className="float-end mx-2">
                          <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="text-reset cursor-pointer" id="dropdownMenuButton5" caret>
                                  <span className="fw-semibold">State:</span> <span className="text-muted">{_.find(states, ['value', state]).label}<i className="mdi mdi-chevron-down ms-1"></i></span>
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                  { states.map(({label, value})=>(
                                    <DropdownItem key={value} onClick={()=>setState(value)}>{label}</DropdownItem>
                                  ))
                                  }
                              </DropdownMenu>
                          </UncontrolledDropdown>
                      </div>
                      <h4 className="card-title mb-4">Categories by Price</h4>
                      <SimpleBar className="simple-bar">
                          <div className="table-responsive">
                              <Table className="table-borderless table-centered table-nowrap">
                                  <tbody>
                                    { data.map((order, index)=>(
                                      <tr key={index}>
                                          <td>
                                              <h6 className="font-size-15 text-capitalize mb-1 fw-normal">{order.category}</h6>
                                          </td>
                                          <td className="text-muted fw-semibold text-end">
                                              ฿{numberFormat(order.price, 0)}
                                          </td>
                                      </tr>
                                    ))  }
                                    {
                                      !data.length && <tr><td>No data found.</td></tr>
                                    }
                                  </tbody>
                              </Table>
                          </div>
                      </SimpleBar>
                  </CardBody>
              </Card>
          </React.Fragment>
      )
}

export default TopUser
